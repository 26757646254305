<template>
    <div class="w-1/2 p-2 max-h-32">
        <div class="border rounded shadow p-5">
            <div class="border-b p-3 flex justify-between align-center">
                <div>
                    <p class="uppercase text-gray-600">CONNEXIONS UTILISATEURS</p>
                    <p class="font-extralight text-sm">{{ data.length }} utilisateurs uniques</p>
                </div>
                <div>
                    <button
                        @click="exportFile()"
                        class="transition-all duration-500 bg-indigo-400 cursor-pointer hover:bg-indigo-500 text-white font-bold py-2 px-4 border border-indigo-400 rounded">
                        Export
                    </button>
                </div>
            </div>
            <div class="overflow-y-auto" style="height:41vh;">
                <table class="relative w-full table-auto mt-5">
                    <thead class="shadow-sm text-gray-700 border-b-2 border-blue-50 bg-white text-left sticky top-0 transition-all">
                        <tr>
                            <th class="pb-5 font-medium">Dernière connexion</th>
                            <th class="pb-5 font-medium">Date/Heure</th>
                            <th class="pb-5 font-medium">Nom d'utilisateurs</th>
                            <th class="pb-5 font-medium">Entreprise</th>
                            <th class="pb-5 font-medium">Nb connexion{{ '('+days+'j)' }}</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-blue-50">
                        <tr v-for="(row, index) in data" :key="index">
                            <td class="p-3 font-extralight">
                                {{ (calc_relative_date(row.datetime) != 0) ? 'Il y a ' + calc_relative_date(row.datetime) + ' jour' + ((calc_relative_date(row.datetime) != 1) ? 's.' : '.') : "Aujourd'hui" }}
                            </td>
                            <td class="p-3 font-extralight">
                                {{ calc_date_fr(row.datetime) }} / {{ split_string(row.datetime) }}
                            </td>
                            <td class="p-3 font-extralight">
                                {{ row.username }}
                            </td>
                            <td class="p-3 font-extralight">
                                {{ get_name_company(row.company) }}
                            </td>
                            <td class="p-3 font-extralight">
                                {{ nb_connect[row.username] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import XLSX from 'xlsx'
import axiosGet from '@/assets/script/axios.js'

export default {
    name: 'TableConnexion',
    props: {
        url: String,
        height: String,
        company: Number,
        days: Number,
    },
    data () {
        return {
            data: [],
            nb_connect: {},

            companies: [],

            loading: false,
        }
    },
    methods: {
        exportFile () {
            let name = 'data-user-connexion-' + this.getDateNow() + '.xlsx'
            let data_excel = this.getDataExcel()
            let work_sheet = XLSX.utils.json_to_sheet(data_excel)
            let work_book = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(work_book, work_sheet, 'data')

            XLSX.writeFile(work_book, name)
        },
        getDateNow () {
            let now = new Date()
            return now.getDate() + '/' + now.getMonth() + '/' + now.getFullYear() + '-' + now.getHours() + 'h-' + now.getMinutes()
        },
        async getCompanies () {
          await axiosGet.get('/users', {}).then(response => {this.companies = response.data})
        },
        getDataExcel () {
            let array = []

            this.data.forEach(element => {
                let date = new Date(element.datetime)
                let tmp = {}

                tmp['Dernière connexion'] = parseInt(this.calc_relative_date(element.datetime))
                tmp['Date'] = ((date.getDate() < 10) ? '0' + date.getDate() : date.getDate()) + '/' + ((date.getMonth() < 10) ? '0' + date.getMonth() : date.getMonth()) + '/' + date.getFullYear()
                tmp['Entreprise'] = this.get_name_company(element.company)
                tmp["Nom d'utilisateur"] = element.username
                tmp['Nombre de connexions'] = parseInt(this.nb_connect[element.username])
                tmp['Type'] = element.type
                array.push(tmp)
            })
            return array
        },
        async getData () {
            this.data = [].concat()
            this.loading = true
            await axiosGet.get(this.url, {
                params: {
                    last_days: this.days ? this.days : '',
                    company_id: this.company != 0 ? this.company: null,
                    user_uniq: true,
                },
            })
            .then((response) => {
                this.loading = false
                this.data = response.data.data
                this.data = [].concat(this.data.sort((a, b) => {
                    let x = new Date(a.datetime)
                    let y = new Date(b.datetime)

                    if (x < y)
                        return -1
                    else if (x > y)
                        return 1
                    return 0
                }))
                this.nb_connect = response.data.nb_connect
            })
        },
        get_name_company (row_company) {
            let tmp = this.companies.find(element => element.id === row_company)

            if (tmp)
                return tmp.last_name
            return ''
        },
        split_string (string) {
            let tmp = string.split('T')[1].split(':')
            let result = tmp[0] + ':' + tmp[1]

            return result
        },
        calc_date_fr (brut_date) {
            let date = new Date(brut_date)

            return ((date.getDate() < 10) ? '0' + date.getDate() : date.getDate()) + '-' + ((date.getMonth() < 10) ? '0' + (date.getMonth()+1) : (date.getMonth())+1) + '-' + date.getFullYear()
        },
        calc_relative_date (date) {
            let now = new Date()
            let calc = new Date(now.getFullYear() + '-' + ((now.getMonth() < 10) ? '0' + (now.getMonth()+1) : (now.getMonth())+1) + '-' + ((now.getDate() < 10) ? '0' + now.getDate() : now.getDate()))
            let connect = new Date(date.split('T')[0])
            let diff = calc.getTime() - connect.getTime()

            return Math.round(diff / (1000*3600*24))
        }
    },
    watch: {
        days () {
            this.getData()
        },
        company () {
            this.getData()
        },
    },
    async mounted () {
        await this.getCompanies()
        await this.getData()
    }
}
</script>

<style scoped>
</style>