<template>
  <section class="dashboard">
    <div id="nav" class="flex justify-between items-center p-3 bg-indigo-500 shadow-lg">  
    <div>
      <router-link to="/global" 
        class="m-2 rounded px-2 text-white p-1 cursor-pointer"
        active-class="border-b-4 border-white rounded-b-none">
          Global
      </router-link>
      <router-link to="/client" 
        class="m-2 rounded px-2 text-white p-1 cursor-pointer"
        active-class="border-b-4 border-white rounded-b-none">
          Utilisateurs
      </router-link>
    </div>
    <div class="flex justify-between items-center">
      <select v-model="company" class="border border-gray-300 rounded-md px-2 ml-2" @change="$event.target.blur()">
        <option value="">Sélectionner un client</option>
        <option :value="element.id" v-for="element in companies" :key="element.id">{{ element.last_name }}</option>
      </select>
      <div>
        <span @click="updateDays(1)" :class="days==1 ? 'border border-white-700': ''" class="p-1 m-2 rounded px-2 text-white cursor-pointer">Aujourd'hui</span>
        <span @click="updateDays(7)" :class="days==7 ? 'border border-white-700': ''" class="p-1 m-2 rounded px-2 text-white cursor-pointer">7</span>
        <span @click="updateDays(15)" :class="days==15 ? 'border border-white-700': ''" class="p-1 m-2 rounded px-2 text-white cursor-pointer">15</span>
        <span @click="updateDays(30)" :class="days==30 ? 'border border-white-700': ''" class="p-1 m-2 rounded px-2 text-white cursor-pointer">30</span>
        <span @click="updateDays(365)" :class="days==365 ? 'border border-white-700': ''" class="p-1 m-2 rounded px-2 text-white cursor-pointer">365</span>
        <span class="m-2 text-white">Derniers jours</span>
      </div>
    </div>
    <button @click="logout()" class="transition-all duration-500 bg-indigo-500 hover:bg-indigo-700 hover:border-indigo-700 text-white font-bold py-2 px-4 border border-indigo-500 rounded">
      Déconnexion
    </button>
    </div>
    <div class="flex flew-row p-3 flex-wrap flex-grow">
      <LineChart
          id="connexions_line_chart"
          url="/stats/connexions/"
          :background-color="'rgba(153, 102, 255, 0.2)'"
          :border-color="'rgba(153, 102, 255, 1)'"
          :fill="false"
          :company="company"
          :days="days"
          name="Connexions">
      </LineChart>
      <PieChart
          id="exports_chart"
          url="/stats/exports/"
          :company="company"
          :days="days"
          name="Types d'exports">
      </PieChart>
      <PieChart
          id="imports_chart"
          url="/stats/imports/"
          :company="company"
          :days="days"
          name="Types d'imports">
      </PieChart>
      <TableConnexion
          url="/stats/company/users/connexions/"
          :company="company == '' ? 0 : company"
          :companies="companies"
          :days="days"
          height="23vh">
      </TableConnexion>
    </div>
  </section>
</template>

<script>
import axiosGet from '@/assets/script/axios.js'

import LineChart from '@/components/line_chart.vue'
import PieChart from '@/components/pie_chart.vue'
import TableConnexion from '@/components/table_connexion.vue'

export default {
  name: 'view-client',
  props: [],
  components: {
    LineChart,
    PieChart,
    TableConnexion,
  },
  data: () => {
    return ({
      company: '',
      companies: [],
    })
  },
  methods: {
    async getCompanies () {
      await axiosGet.get('/users', {}).then(response => {this.companies = response.data})
    },
    updateDays (days){
      this.$store.commit('updateDays', days)
    },
    logout () {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      this.$router.push('/')
    },
  },
  computed: {
    days () {
      return this.$store.state.days
    },
  },
  mounted: async function () {
    this.getCompanies()
  }
}
</script>

<style scoped>
</style>
